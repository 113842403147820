/* eslint-disable react/style-prop-object */
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CSSModules from 'react-css-modules';
import localStyles from './landscape.module.css';
import secondaryPageStyles from '../../shared/css/secondary-page.module.css';
import sharedStyles from '../../shared/css/shared.module.css';
import MenuItemHolder from '../../components/MenuItemHolder/MenuItemHolder';
import Icon from '../../components/Icon/Icon';

const options = { allowMultiple: true };
const styles = {...secondaryPageStyles, ...localStyles, ...sharedStyles};

function Landscape() {

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);

  return (
    <>
    <Helmet>
        <body style="overflow:hidden"></body>
      </Helmet>
    <div
      styleName='cabana-store'
      style={{ backgroundImage: "url('/images/CB.svg')" }}
    >
      <div styleName='page-title-container'>
        <MenuItemHolder isMain={true} dClass='br-24p'>
          <Icon isMainPage={true} icon='box' />
          <div styleName='item-text mail-text'>OPENING SOON</div>
        </MenuItemHolder>
      </div>
    </div>
    </>
  );
}
export default CSSModules(Landscape, styles, options);
