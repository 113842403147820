/* eslint-disable react/style-prop-object */
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CSSModules from 'react-css-modules';
import localStyles from './drop.module.css';
import secondaryPageStyles from '../../shared/css/secondary-page.module.css';
import sharedStyles from '../../shared/css/shared.module.css';

const options = { allowMultiple: true };
const styles = {...secondaryPageStyles, ...localStyles, ...sharedStyles};

function Bomber() {

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);

  return (
    <>
    <Helmet>
        <body style="overflow:hidden"></body>
      </Helmet>
    <div
      styleName='home-page page-fade-effect long-bg fixed bs-mobile-cover'
      style={{ backgroundImage: "url('/images/BOMBER.png')" }}
    >
      <div styleName='page-title-container'>
        <div styleName='bg-text heading'>THE BOMBER</div>
      </div>
    </div>
    </>
  );
}

export default CSSModules(Bomber, styles, options);
