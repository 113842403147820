import CSSModules from 'react-css-modules';
import styles from './layout.module.css';
import Header from '../Header/header';
import Footer from '../Footer/footer';

const options = { allowMultiple: true };

function Layout(props){
  return(
    <div styleName="container">
      <Header />
      <div styleName="main-page fade-effect">
      {props.children}
      </div>
      <Footer />
    </div>
  )
  }
  
  export default CSSModules(Layout, styles, options);