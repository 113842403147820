// components/dropdown.component.js
import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";
import { Link } from "react-router-dom";
import CSSModules from 'react-css-modules';
import MenuItemHolder from "../MenuItemHolder/MenuItemHolder";
import IconHolder from "../IconHolder/IconHolder";
import Icon from "../Icon/Icon";
import styles from './dropdown.module.css';

const options = { allowMultiple: true };

function DropDown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navItems = props.list;
  DropDown.handleClickOutside = () => setIsOpen(false);

  const selectOption = () => {
    setIsOpen(false);
  };

  return (
    <div styleName="dd-wrapper">
      <div onClick={toggle}>
        <IconHolder>
          <Icon icon="hamburger" />
        </IconHolder>
      </div>
      {isOpen ? (
        <ul styleName="dropdown-list fade-effect">
          {navItems.map((item,index) => (
            <li key={index} onClick={selectOption}>
              <Link  to={`${process.env.PUBLIC_URL}${item.url}`}>
                <MenuItemHolder dropdownMobile={true}>
                  <div>
                    <Icon icon={item.icon} />
                  </div>
                  <div styleName="item-text bold-text">{item.name}</div>
                </MenuItemHolder>
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => DropDown.handleClickOutside,
};

export default onClickOutside(CSSModules(DropDown, styles, options), clickOutsideConfig);
