import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import localStyles from './faq.module.css';
import secondaryPageStyles from '../../shared/css/secondary-page.module.css';
import sharedStyles from '../../shared/css/shared.module.css';
import MenuItemHolder from '../../components/MenuItemHolder/MenuItemHolder';
import Icon from '../../components/Icon/Icon';

const options = { allowMultiple: true };
const styles = {...secondaryPageStyles, ...localStyles, ...sharedStyles};

function Faq() {
  return (
    <div
      styleName='home-page page-fade-effect long-bg'
      style={{ backgroundImage: "url('/images/BG2.png')" }}
    >
      <div styleName='page-title-container'>
        <div styleName='page-title-block'>Faq</div>
      </div>

      <div styleName='content-wrapper'>
        <div styleName='content-headline'>WHAT IS BONEHEADS?</div>
        <div styleName='content-text content-text-sm'>
          #BONEHEADS IS A COLLECTION OF 10,000 UNIQUE DIGITAL NFT COLLECTIBLES
          CREATED USING A GENERATIVE ALGORITHM AND STORED ON THE ETHEREUM
          BLOCKCHAIN AS ERC-721 TOKENS. SEVEN BRILLIANT COLLABORATORS SPENT OVER
          1,500+ HOURS DEVELOPING OVER 500+ ATTRIBUTES, ACROSS 8 CATEGORIES, IN
          9K HIGH-RESOLUTION. OWNING A #BONEHEAD WILL GRANT YOU EXCLUSIVE ACCESS
          TO SPECIAL ROADMAP PERKS, SUCH AS ACCESS TO THE CABANA - WHICH WILL BE
          A PHYSICAL + DIGITAL IDENTITY FASHION-GAMING WEARABLES PLATFORM FROM
          WHICH YOU CAN MINT AND FORGE DIGITAL + PHYSICAL COLLECTIBLES.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>RELEASE DATE?</div>
        <div styleName='content-text content-text-sm'>
          WE'RE DOING A SURPRISE DROP AND THE ANNOUNCEMENT WILL COME THE DAY OF
          THE DROP. WE WILL NOTIFY OUR DISCORD SERVER AFTER THE SMART CONTRACT
          HAS BEEN DEPLOYED AND THE MINTING PROCESS HAS BEEN INITIATED. WE WILL
          TRY TO NOTIFY EVERYONE AT LEAST A FEW HOURS AHEAD OF TIME. STAY READY.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          HOW MANY WILL BE AVAILABLE IN THE PRESALE?
        </div>
        <div styleName='content-text content-text-sm'>
          THERE WILL BE 9,500 #BONEHEADS AVAILABLE DURING THE PRESALE. 200 ARE
          RESERVED FOR COMMUNITY GIVEAWAYS, BOUNTIES, GROWTH INCENTIVES, AND
          #BONEGANG EMPOWERMENT. 200 ARE RESERVED FOR THE CURRENT DEVELOPMENT
          TEAM (12 EMPLOYEES), THE FOUNDERS, AND OUR INVESTORS. 100 ARE RESERVED
          IN CONSIDERATION FOR FUTURE HIRES, INVESTORS, AND COLLABORATORS. THERE
          WILL BE A 5% ROYALTY ON SECONDARY SALES, THIS WILL GO TOWARDS
          ESTABLISHING A COMMUNITY-POWERED CREATOR FUND, COMMUNITY BOUNTIES, AS
          WELL AS HIRING THE BEST DEVELOPERS.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          WHERE CAN I PURCHASE A #BONEHEAD NFT?
        </div>
        <div styleName='content-text content-text-sm'>
          YOU WILL ONLY BE ABLE TO PARTICIPATE IN THE PRESALE ON OUR OFFICIAL
          WEBSITE. AFTER THE INITIAL MINTING PROCESS, YOU WILL BE ABLE TO TRADE
          YOUR #BONEHEADS ON OPENSEA. THE WEBSITE WILL BE REVEALED BEFORE THE
          MINTING OPENS. STAY READY.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          HOW MUCH WILL IT COST TO BUY A #BONEHEAD?
        </div>
        <div styleName='content-text content-text-sm'>
          DUE TO THIS BEING A SURPRISE DROP, THE PRICE WILL NOT BE REVEALED
          UNTIL THE SMART CONTRACT HAS BEEN DEPLOYED AND THE MINTING HAS BEEN
          ACTIVATED ON OUR WEBSITE. THERE WILL NOT BE A BONDING CURVE.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>HOW MANY #BONEHEADS CAN I BUY?</div>
        <div styleName='content-text content-text-sm'>
          THE MAXIMUM NUMBER OF #BONEHEADS THAT YOU WILL BE ABLE TO MINT PER TX
          IS 30.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>HOW WAS THE PRICE DETERMINED?</div>
        <div styleName='content-text content-text-sm'>
          THIS IS NOT YOUR RUN-OF-THE-MILL HALF-BODIED ANIMAL COLOURING-BOOK
          CATALOG PROJECT (NO-OFFENCE, WE LOVE YOU). NOT ALL PROJECTS ARE
          CREATED EQUAL. THIS IS A REAL BUSINESS, WITH EXPERIENCED INVESTORS,
          AND A CLEARLY DEFINED OBJECTIVE. #BONEHEADS ARE PRICED IN ACCORDANCE
          TO THE LEVEL OF EFFORT, QUALITY, FUNDING SPENT TO DATE, AND TO THE
          FUNDING THAT IS REQUIRED FOR US TO DELIVER ON OUR VISION AND ROADMAP -
          WE DO NOT SEE THIS AS A “QUICK” WAY TO MAKE MONEY - OUR GOAL IS NOT TO
          MERELY BUILD HYPE AND SELL OUT IN 6 HOURS (ALTHOUGH THAT WOULD BE
          NICE), WHICH IS WHY WE ELECTED TO DO A SURPRISE DROP. MOST ~10K TOKEN
          PROJECTS HAVE SOLD OUT WITHIN THE FIRST ~6HRS AND ENDED UP IN THE
          HANDS OF ~1-1.5K WALLETS, WITH THOUSANDS OF FAILED TRANSACTIONS. BY
          PRICING OUR #BONEHEADS A LITTLE BIT HIGHER THAN WHAT THE COMMUNITY IS
          USED TO, WE BELIEVE THAT THIS CREATES BETTER DISTRIBUTION WHILE WILL
          GIVE MORE INDIVIDUALS AN OPPORTUNITY TO PARTICIPATE IN THE PRESALE AND
          JOIN OUR BURGEONING COMMUNITY, AND IT WILL GIVE US THE NECESSARY
          RUNWAY FOR BUILDING OUT OUR PLATFORM AND DEVELOPING OUR BRAND. WE’RE
          HERE FOR THE LONG HAUL. OUR GOAL IS TO CREATE A MULTIMEDIA FRANCHISE
          SPANNING THE RAPIDLY EVOLVING PHYSICAL AND DIGITAL DESIGN + FASHION +
          GAMING + ART SPECTRUM AND ITERATE UNTIL WE’VE DEVELOPED SOMETHING THAT
          IS TRULY UNIQUE AND IMAGINATIVE.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>BUT ISN'T THAT STILL EXPENSIVE?</div>
        <div styleName='content-text content-text-sm'>
          THE NFT SPACE IS RELATIVELY VOLATILE, AT THE BLEEDING EDGE, AND VERY
          UNPREDICTABLE. AS MUCH AS MANY INDIVIDUALS, "EXPERTS, AND TWITTER
          MEGAPHONES BELIEVE THAT EVERYTHING IS "GOING TO THE MOON", ~95% OF NFT
          PROJECTS WILL END UP BEING WORTHLESS WITHIN A FEW YEARS - BUT THE ONES
          THAT OUTPERFORM AND CREATE SOMETHING MEANINGFUL WILL BE WORTH ORDER OF
          MAGNITUDES MORE THAN WHAT THEY DID INITIALLY. IF YOU HAPPEN TO LIKE
          OUR ART, AND BELIEVE IN OUR VISION AND ROADMAP, AND HAPPEN TO MAKE A
          PURCHASE, WE THANK YOU FOR YOUR SUPPORT. THIS IS NOT FINANCIAL ADVICE,
          NOR WILL WE EVER OFFER FINANCIAL ADVICE.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          WHY DON'T YOU JUST MAKE THE MAX SUPPLY PER TX LOWER?
        </div>
        <div styleName='content-text content-text-sm'>
          EVEN IF WE DECREASED IT TO 1 #BONEHEAD PER TRANSACTION, INDIVIDUALS
          CAN EASILY CIRCUMVENT THIS FUNCTION.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          WHAT HAPPENS WHEN I MINT A #BONEHEAD(S)?
        </div>
        <div styleName='content-text content-text-sm'>
          IF THE TX IS SUCCESSFUL, IT/THEY WILL APPEAR IN YOUR WALLET. HOWEVER,
          THEY WILL NOT BE REVEALED UNTIL A LATER DATE. ONCE THE METADATA FOR
          YOUR TOKEN IS UPLOADED, AND THEN FROZEN, YOU WILL BE ABLE TO VIEW YOUR
          #BONEHEAD ON OPENSEA.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          WHEN WILL THE #BONEHEADS BE REVEALED?
        </div>
        <div styleName='content-text content-text-sm'>
          THE REVEAL DATE WILL BE ANNOUNCED ONE WEEK AFTER ALL 10,000 #BONEHEADS
          HAVE BEEN MINTED/SOLD.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          WILL YOU BE DROPPING PHYSICAL COLLECTIBLES?
        </div>
        <div styleName='content-text content-text-sm'>
          Yes. only token holders will be able to forge physical collectibles.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          DOES OWNING A #BONEHEAD TOKEN GIVE ME ANY EXCLUSIVE ACCESS?
        </div>
        <div styleName='content-text content-text-sm'>
          YES. PLEASE VISIT{' '}
          <Link to={`${process.env.PUBLIC_URL}/benefits`}>
            <span styleName='underline hover-opacity'>BENEFITS</span>
          </Link>{' '}
          TO LEARN MORE INFORMATION REGARDING THIS TOPIC.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          IS #BONEHEADS JUST AN ART-BASED NFT COLLECTION, A FASHION BRAND, A
          GAMING COMPANY, AN APP, OR A SOFTWARE PROVIDER?
        </div>
        <div styleName='content-text content-text-sm'>
          ALL OF THE ABOVE, AND THEN SOME. #BONEHEADS IS A PHYSICAL AND DIGITAL
          IDENTITY FASHION-GAMING WEARABLES AND AVATAR CREATION PLATFORM. IN
          SHORT, WE'RE DEVELOPING AN APPLICATION THAT WILL ALLOW YOU TO MAKE AN
          AVATAR WITH ALL THE DRIP YOU COULD POSSIBLY IMAGINE. WE WILL ALSO BE
          CREATING COMMODITIES, A BRAND IF YOU WILL, WHICH WILL CONSIST OF
          EXCLUSIVE COLLECTIBLES SUCH AS CLOTHING AND ACCESSORIES THAT WILL BE
          AUTHENTICATED USING QR CODES AND TOKENS TO VERIFY AUTHENTICITY
          ON-CHAIN - PHYSICAL + DIGITAL = #NOMOREFAKES #BONEHEADSFOREVER
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>
          WHEN WILL I GET ACCESS TO THE APP?
        </div>
        <div styleName='content-text content-text-sm'>
          WE ARE CURRENTLY PLANNING ON RELEASING THE AVATAR CREATION APP BETA
          SOMETIME WITHIN THE NEXT 8-12 MONTHS. WE'VE BEEN WORKING ON THIS IN
          STEALTH MODE FOR OVER A YEAR, AND HAVE SPENT A TREMENDOUS AMOUNT OF
          FUNDING MAKING SURE WE WORK WITH THE BEST ILLUSTRATORS, ARTISTS,
          DEVELOPERS, DESIGNERS, AND PATTERN MAKERS TO MAKE THIS DREAM BECOME A
          REALITY, AND WE CAN'T WAIT TO SHARE THIS WITH YOU.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>HOW ARE YOU DIFFERENT?</div>
        <div styleName='content-text content-text-sm'>
          Unlike other art-only NFT based projects that are only art based,
          #BONEHEADS is different in the sense that it's also a fashion + gaming
          company that will allow you to forge physical collectibles (jackets,
          boxing gloves, playing cards, area rugs, and more) if you're a token
          holder. Our token has an actual utility, and our business model is
          MINT + FORGE.
        </div>
      </div>
      <div styleName='content-wrapper mb-0'>
        <div styleName='content-headline'>Why did you create this?</div>
        <div styleName='content-text content-text-sm'>
          We care. We care about technology, and we care about fashion. We care
          about engineering, and we care about design. We like nice things, and
          we like a clean and immersive user experience. We care about most
          people (jk, all), and we want to create the best brand, app, platform,
          art, and digital experience possible for them to enjoy and cultivate a
          community around -- and If in the process we can empower individuals
          to further express themselves and find confidence in their
          distinctiveness to be able to share more of their authentic selves,
          then we can die happily ever after.
        </div>
      </div>
      <div styleName='forever'>#BONEHEADSFOREVER</div>
      <div styleName='getabone'>
        <MenuItemHolder>
          <Icon icon='mint' />
          <div styleName='item-text bold-text'>
            <Link to={`${process.env.PUBLIC_URL}/connect`}>
              MINT A BONEHEAD
            </Link>
          </div>
        </MenuItemHolder>
      </div>
    </div>
  );
}
export default CSSModules(Faq, styles, options);
