import CSSModules from 'react-css-modules';
import styles from './menu-item-holder.module.css';

const options = { allowMultiple: true };

function MenuItemHolder(props) {
  const dynamicClass = props.dClass ? props.dClass : '';
  const isLocked = props.isLocked ? 'locked-menu-item' : '';
  const dropdownItem = props.dropdownItem ? 'dropdownItem' : '';
  const isMain = props.isMain ? 'main-btn' : '';
  const dropdownMobile = props.dropdownMobile ? 'list-item-mobile': '';
  return (
    <div
      styleName={`${dynamicClass} ${isLocked} ${dropdownItem} ${isMain} ${dropdownMobile} menu-item-holder`}
    >
      {props.children}
    </div>
  );
}
export default CSSModules(MenuItemHolder, styles, options);
