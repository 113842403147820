import { useState } from "react";
import CSSModules from 'react-css-modules';
import styles from './dropdown-component.module.css';
import IconHolder from "../IconHolder/IconHolder";
import Icon from "../Icon/Icon";
import DropdownList from "../DropdownList/dropdown-list";

const options = { allowMultiple: true };

function DropdownComponent(props) {
  const [dstatus, setDStatus] = useState(false);
  const navIcon = props.icon;
  const navItems = props.list;
  const onChange = props.onChange;
  const showDropdown = () => {
    setDStatus(true);
  };
  const hideDropdown = () => {
    setDStatus(false);
  };

  return (
    <div
      styleName="dropdown"
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
    >
      <IconHolder>
        <Icon icon={navIcon} />
      </IconHolder>
      {dstatus && (
        <ul styleName="dropdown-list fade-effect">
          <DropdownList list={navItems} onChange={onChange} />
        </ul>
      )}
    </div>
  );
}

export default CSSModules(DropdownComponent, styles, options);
