import Web3 from 'web3';
import React from 'react';
import CONTRACT_ABI from '../../assets/contract-abi.json';

const InfuraWeb3 = (props) => {
  const web3 = new Web3(
    new Web3.providers.HttpProvider(
      'https://mainnet.infura.io/v3/4b48220ef22f43c1a1c842c850869019'
    )
  );

  React.useEffect(() => {
    console.log('calculation process has begun');
    fetch(
      `https://api.etherscan.io/api?module=contract&action=getabi&address=0xf9C2874A88ED6C5a8115CB5693996C8fE22B3CE2`
    )
      .then((res) => res.json())
      .then(async (data) => {
        var contractABI = CONTRACT_ABI;

        var MyContract = new web3.eth.Contract(contractABI);
        MyContract.options.address =
          '0xf9C2874A88ED6C5a8115CB5693996C8fE22B3CE2';
        await MyContract.methods
          .totalSupply()
          .call()
          .then((result) => props.setTotalSupply(result));
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.calcTotalSupply]);

  return (
    <div>
      <div styleName="heading" className="totalMinted">
        TOTAL MINTED
      </div>
      <div styleName="minted-value" className="boneheadRemain">
        {' '}
        {/* {console.log(props.totalSupply)} */}
        {props.totalSupply} / 10,000
      </div>
      {/* <div styleName="heading">Total Minted</div>
                <div styleName="minted-value">0/10,000</div> */}
    </div>
  );
};

export default InfuraWeb3;
