import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import localStyles from './roadmap.module.css';
import sharedStyles from '../../shared/css/shared.module.css';
import MenuItemHolder from '../../components/MenuItemHolder/MenuItemHolder';
import Icon from '../../components/Icon/Icon';

const options = { allowMultiple: true };
const styles = {...localStyles, ...sharedStyles};

function RoadMap() {
  return (
    <div
      styleName='home-page page-fade-effect long-bg'
      style={{ backgroundImage: "url('/images/BG2.png')" }}
    >
      <div styleName='page-title-container'>
        <div styleName='page-title-block'>Roadmap</div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>CODE-BASED DIGITALS</div>
        <ul styleName='content-text context-text-list'>
          <li>5% COMMUNITY WALLET</li>
          <li>3D BONEHEADS</li>
          <li>DYNAMIC LANDSCAPES</li>
          <li>BoMA (BUNKER OF MODERN ART) = GALLERY</li>
          <li>CLAIMABLES</li>
          <li>BABY BONES</li>
          <li>SPACEHEADS (ALIEN INVASION)</li>
          <li>BONING = BREEDING</li>
          <li>THE SPA = LIQUIDITY POOL</li>
          <li>CONSUMER-FACING AVATAR APP</li>
          <li>PARTNER SDK</li>
        </ul>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>NFT POST-SALE PHYSICALS</div>
        <div styleName='content-text'>
          <p>
            <span styleName='text-white'>MOUSE-PAD </span>- 50 RANDOM #BONEHEADS
            WILL RECEIVE A FREE NUMBERED (+QR/NFC) MOUSE-PAD MATCHING THE EXACT
            FACIAL EXPRESSION OF YOUR #BONEHEAD TOKEN
          </p>
          <p>
            <span styleName='text-white'>HOODIE </span>- 25 RANDOM #BONEHEADS
            WILL RECEIVE A FREE NUMBERED (+QR/NFC) HOODIE
          </p>
          <p>
            <span styleName='text-white'>AREA RUG </span>- 10 RANDOM #BONEHEADS
            WILL RECEIVE A FREE NUMBERED (+QR/NFC) 4FT AREA RUG MATCHING THE
            EXACT FACIAL EXPRESSION OF YOUR #BONEHEAD RUG MATCHING THE EXACT
            FACIAL EXPRESSION OF YOUR #BONEHEAD
          </p>
          <p>
            <span styleName='text-white'>PAINTING </span>- 5 RANDOM #BONEHEADS
            WILL RECEIVE A FREE NUMBERED (+QR/NFC) 40X60 (GALLERY DEPTH)
            PAINTING OF YOUR #BONEHEAD
          </p>
          <p>
            VERIFICATION: EACH ITEM WILL COME NUMBERED AND IN A BEAUTIFULLY
            CRAFTED BRANDED MAGNETIC BOX, WITH EITHER A QR CODE OR AN NFC TAG TO
            VERIFY AUTHENTICITY ON-CHAIN. PHYSICAL + DIGITAL = #NOMOREFAKES
            #BONEHEADSFOREVER
          </p>
        </div>
      </div>
      <div styleName='content-wrapper mb-0'>
        <div styleName='content-headline'>PHYISICAL COLLECTIBLES</div>
        <ul styleName='content-text context-text-list'>
          <li>5FT LIFESIZE SCULPTURES</li>
          <li>2.4 FT SCULPTURES</li>
          <li>4FT AREA RUGS</li>
          <li>PLAYING CARDS</li>
          <li>BOXING GLOVES</li>
          <li>TRUCKER HATS</li>
          <li>MINI BACKPACKS</li>
          <li>PUFFER JACKETS</li>
          <li>BIKER JACKETS</li>
          <li>BOMBER JACKETS</li>
          <li>T-SHIRTS</li>
          <li>HOODIES</li>
          <li>SLIDES</li>
          <li>SOCKS</li>
        </ul>
      </div>
      <div styleName='forever'>#BONEHEADSFOREVER</div>
      <div styleName='getabone'>
        <MenuItemHolder>
          <Icon icon='mint' />
          <div styleName='item-text bold-text'>
            <Link to={`${process.env.PUBLIC_URL}/connect`}>
              MINT A BONEHEAD
            </Link>
          </div>
        </MenuItemHolder>
      </div>
    </div>
  );
}
export default CSSModules(RoadMap, styles, options);
