const navItems = {
  primary: [
    {
      name: "About",
      icon: "whitepaper",
      url: "/about",
      status: true,
    },
    {
      name: "Roadmap",
      icon: "roadmap",
      url: "/roadmap",
      status: true,
    },
    {
      name: "Benefits",
      icon: "benefits",
      url: "/benefits",
      status: true,
    },
    {
      name: "FAQ",
      icon: "faq",
      url: "/faq",
      status: true,
    },
    {
      name: "Community",
      icon: "community",
      url: "/community",
      status: true,
    },
    {
      name: "Investors",
      icon: "dollar-coin",
      url: "/investors",
      status: true,
    },
    {
      name: "Contact",
      icon: "email",
      url: "/contact",
      status: true,
    },
  ],
  primaryMobile: [
    {
      name: "About",
      icon: "whitepaper",
      url: "/about",
      status: true,
    },
    {
      name: "Roadmap",
      icon: "roadmap",
      url: "/roadmap",
      status: true,
    },
    {
      name: "Benefits",
      icon: "benefits",
      url: "/benefits",
      status: true,
    },
    {
      name: "FAQ",
      icon: "faq",
      url: "/faq",
      status: true,
    },
    {
      name: "Community",
      icon: "community",
      url: "/community",
      status: true,
    },
    {
      name: "CABANA",
      icon: "box",
      url: "/store",
      status: true,
    },
    {
      name: "Investors",
      icon: "dollar-coin",
      url: "/investors",
      status: true,
    },
    {
      name: "Contact",
      icon: "email",
      url: "/contact",
      status: true,
    },
  ],
  secondary: [
    {
      name: "THE CABANA (STORE)",
      icon: "box",
      url: "/store",
      status: true,
    },
    {
      name: "THE BoMA (GALLERY)",
      icon: "eye",
      url: "/",
      status: false,
    },
    {
      name: "THE SPA (LIQUIDITY POOL)",
      icon: "droplet",
      url: "/",
      status: false,
    },
  ],
  dropList: [
    {
      name: "DROP 1",
      icon: "box",
      url: "/puffer",
      status: true
    },
    {
      name: "DROP 2",
      icon: "box",
      url: "/biker",
      status: true
    },
    {
      name: "DROP 3",
      icon: "box",
      url: "/bomber",
      status: true
    },
  ]
};

export default navItems;