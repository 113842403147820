import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import localStyles from './about.module.css';
import secondaryPageStyles from '../../shared/css/secondary-page.module.css';
import sharedStyles from '../../shared/css/shared.module.css';
import MenuItemHolder from '../../components/MenuItemHolder/MenuItemHolder';
import Icon from '../../components/Icon/Icon';

const options = { allowMultiple: true };
const styles = {...secondaryPageStyles, ...localStyles, ...sharedStyles};

function About() {
  return (
    <div
      styleName='home-page page-fade-effect long-bg'
      style={{ backgroundImage: "url('/images/BG2.png')" }}
    >
      <div styleName='page-title-container'>
        <div styleName='page-title-block'>About</div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>WHAT ARE BONEHEADS?</div>
        <div styleName='content-text content-text-sm text-uppercase'>
          THE COOLEST DIGITAL COMPANIONS THAT YOU’VE EVER SEEN. THEY’RE ERC-721
          TOKENS, BUT THEY’RE ALSO MUCH MORE THAN THAT. WHY DO THEY LOOK SO
          GOOD? BESIDES BEING NATURALLY HANDSOME, SEVEN BRILLIANT COLLABORATORS
          SPENT OVER 1,500+ HOURS DEVELOPING OVER 500+ ATTRIBUTES ACROSS 8
          CATEGORIES, IN 8K HIGH-RESOLUTION, FOR A TOTAL OF 10,000 UNIQUE
          #BONEHEADS. SINCE THIS IS A SURPRISE DROP, THE PRICING WILL NOT BE
          REVEALED UNTIL THE DAY OF THE MINT. THERE ARE 9,500 #BONEHEADS
          AVAILABLE IN THE PRESALE. 200 ARE WITHHELD FOR COMMUNITY ACTIVATIONS,
          BOUNTIES, AND GIVEAWAYS. 200 ARE WITHHELD FOR THE CURRENT DEVELOPMENT
          TEAM, THE FOUNDERS, AND OUR INVESTORS - AND 100 ARE RESERVED IN
          CONSIDERATION FOR FUTURE HIRES, INVESTORS, AND COLLABORATORS. THERE
          WILL BE A 10% ROYALTY ON SECONDARY SALES, THIS WILL GO TOWARDS
          ESTABLISHING A COMMUNITY-POWERED CREATOR FUND. THE MAX SUPPLY PER TX
          IS 30 #BONEHEADS. THIS IS NOT YOUR RUN-OF-THE-MILL HALF-BODIED ANIMAL
          COLORING-BOOK CATALOG PROJECT (NO OFFENSE, WE LOVE YOU). NOT ALL
          PROJECTS ARE CREATED EQUAL. THIS IS A REAL BUSINESS, WITH EXPERIENCED
          INVESTORS, AND A CLEARLY DEFINED OBJECTIVE. #BONEHEADS ARE PRICED IN
          ACCORDANCE TO THE LEVEL OF EFFORT, QUALITY, FUNDING SPENT TO DATE, AND
          TO THE FUNDING THAT IS REQUIRED FOR US TO DELIVER ON OUR VISION AND
          ROADMAP - WE DO NOT SEE THIS AS A “QUICK” WAY TO MAKE MONEY - OUR GOAL
          IS NOT TO MERELY BUILD HYPE AND SELL OUT IN 6 HOURS (ALTHOUGH THAT
          WOULD BE NICE), WHICH IS WHY WE ELECTED TO DO A SURPRISE DROP. MOST
          ~10K TOKEN PROJECTS HAVE SOLD OUT WITHIN THE FIRST ~6HRS AND ENDED UP
          IN THE HANDS OF ~1.5K WALLETS. BY PRICING OUR #BONEHEADS A LITTLE BIT
          HIGHER THAN WHAT THE COMMUNITY IS USED TO, WE BELIEVE THAT THIS WILL
          GIVE MORE INDIVIDUALS AN OPPORTUNITY TO PARTICIPATE AND JOIN OUR
          BURGEONING COMMUNITY, AND IT WILL GIVE US THE NECESSARY RUNWAY FOR
          ATTRACTING TALENT AND BUILDING OUT OUR PLATFORM. WE’RE HERE FOR THE
          LONG HAUL. OUR GOAL IS TO CREATE A MULTIMEDIA FRANCHISE SPANNING THE
          RAPIDLY EVOLVING PHYSICAL AND DIGITAL DESIGN + FASHION + GAMING + ART
          SPECTRUM AND ITERATE UNTIL WE’VE DEVELOPED SOMETHING THAT IS TRULY
          UNIQUE AND IMAGINATIVE. WE WILL BE PUTTING RELENTLESS FOCUS INTO
          DEVELOPING THE BACKBONE OF OUR BUSINESS, WHICH WILL BE A PHYSICAL +
          DIGITAL IDENTITY FASHION AND GAMING WEARABLES NFT PLATFORM FROM WHICH
          YOU WILL BE ABLE TO MINT AND FORGE DIGITAL + PHYSICAL COLLECTIBLES.
          CONSIDER THE #BONEHEADS AS THE CURRENT MANNEQUINS. INTEROPERABILITY
          ACROSS CHAINS IS SOMETHING THAT WE WILL WORK TOWARDS, STEADILY. SDK
          DEVELOPMENT HAS ALREADY BEGUN. UNTIL THEN, WE PRESENT TO YOU THESE
          #BONEHEAD COMPANIONS. THANK YOU FOR YOUR SUPPORT. #BONEHEADSFOREVER
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>WHY DID WE MAKE BONEHEADS?</div>
        <div styleName='content-text content-text-sm text-uppercase'>
          OUR CORE TEAM HAS SPENT THE BETTER PART OF OUR ADULT LIVES BUILDING
          STARTUPS AND HELPING OTHERS ACHIEVE THEIR GOALS IN A COLLABORATIVE
          WAY. OVER THE YEARS, WE’VE BEEN LUCKY ENOUGH TO WORK WITH TALENTED AND
          CREATIVE INDIVIDUALS FROM ALL OVER THE WORLD. BEING DESIGNERS AND
          PRODUCT MANAGERS, WE HAVE A LITTLE NERDY BONE IN US - IT COMES WITH
          THE TERRITORY. WE’RE ALL VERY MUCH OBESSED WITH THE PRIVELEGE AND
          OPPORTUNITY TO ENVISION, SHAPE, AND SHIP DIGITAL PRODUCTS THAT CAN
          AFFECT POSITIVE EMOTIONS, CHANGE, AND CREATE AN IMPACT. TOGETHER,
          WE’VE CULTIVATED OVER 40+ YEARS OF EXPERIENCE IN DESIGN, PRODUCT
          MANAGEMENT, MANUFACTURING, AND MARKETING, TO BRING YOU OUR BRAINCHILD
          - BONEHEADS. FROM INCEPTION, #BONEHEADS HAS BEEN A COLLABORATIVE
          EFFORT SPANNING MORE THAN 6 COUNTRIES AND 14 TALENTED INDIVIDUALS, BUT
          WE’RE JUST GETTING STARTED. #BONEHEADS IS A SIMBIOTIC RELATIONSHIP
          BETWEEN PHYSICAL AND DIGITAL COLLECTIBLES. WE PLAN ON MAKING AND
          DEVELOPING PHYSICAL + DIGITAL PRODUCTS AND REPRESENTATIONS OF ANYTHING
          THAT WE THINK IS COOL IN THE WORLD, LIKE LIFE-SIZE 5FT BONEHEAD
          SCULPTURES, TOYS, FUZZY ZEBRA PRINT MINI BACKPACKS, ALIGATOR PUFFER
          VESTS, STUDDED BOXING GLOVES, AND MORE. YOU WILL BE ABLE TO UTILIZE
          THESE COLLECTIBLE WEARABLES AS A MEANS OF CURATING YOUR DIGITAL AND
          PHYSICAL IDENTITY. WE BELIVE THAT HAVING A DIGITAL IDENTITY IS THE
          FUTURE, AND ITS ALREADY HERE, IT JUST HASN’T BEEN EVENLY DISTRIBUTED.
          HOWEVER WE BELIEVE THAT WE HAVE AN INSIGHT INTO SOMETHING THAT MOST
          CURRENT METAVERSE DEVELOPERS ARE OVERLOOKING, THE PHYSICAL CONNECTION.
          WE BELIEVE THAT INDIVIDUALS CRAVE THE ABILITY TO EXPRESS THEMSELVES IN
          BOTH WORLDS. THE WORLD IS CHANGING RAPIDLY, AND WE PLAN ON
          CONTRIBUTING TO SOME OF THAT BEAUTIFUL CHAOS. THIS IS WHY WE MADE
          #BONEHEADS. #boneheadsforever.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>WHY ARE WE DIFFERENT?</div>
        <div styleName='content-text content-text-sm text-uppercase'>
          OUR PRIORITY IS TO DEVELOP A PHYSICAL + DIGITAL IDENTITY PLATFORM.
          WHAT DOES THIS MEAN? THIS MEANS THAT YOU WILL BE ABLE TO HAVE TWO
          SELF(S). A PHYSICAL SELF, AND A DIGITAL SELF. MOST DEVELOPERS ARE
          WORKING ON THE DIGITAL ASPECT. INCREDIBLE METAVERSE APPLICATIONS AND
          USE CASES ARE BEING DEVELOPED. PEOPLE WANT TO STUNT IN DIGITAL LIFE,
          AVATAR ADOPTION AND EXPRESSION IS HERE, FOR GOOD, BUT WE HAVE AN
          INSIGHT THAT MOST DEVELOPERS ARE SEEMINGLY OVERLOOKING: PEOPLE ALSO
          WANT TO STUNT IN PHYSICAL LIFE. THAT WILL NEVER CHANGE. ONLY A HANDFUL
          OF DEVELOPERS ARE FOCUSING ON CREATING A PRODUCT/BRAND THAT
          FACILITATES A SYMBIOTIC RELATIONSHIP BETWEEN YOUR DIGITAL AND PHYSICAL
          IDENTITY. OUR BUSINESS MODEL IS MINT + FORGE. YOU WILL BE ABLE TO
          FORGE PHYSICAL COLLECTIBLES (GRAILS, CLOTHING, ACCESSORIES, AND MORE)
          OF THE DIGITAL REPRESENTATIONS THAT WE RELEASE THROUGH NFTS THROUGH
          THE CABANA. ONLY TOKEN HOLDERS WILL BE ABLE TO PARTICIPATE IN THE
          EXCLUSIVE DROPS THAT WE RELEASE. WE DON'T JUST PLAN ON GIVING YOU THE
          MOST ROBUST DIGITAL EXPERIENCE, BUT WE'RE ALSO GOING TO TURN YOU INTO
          PHYSICAL GRAIL GODS. THE DRIP WILL BE UNDENIABLE, AND WHAT'S THE FUN
          IN FLEXING IF YOU CAN'T DO IT IN BOTH REALITIES? PHYSICAL + DIGITAL =
          #BONEHEADS. EVERY ITEM, SCULPTURE, PAINTING, AND ANY PHYSICAL GOOD
          THAT WE RELEASE WILL BE CONSIDERED A COLLECTIBLE FIRST AND FOREMOST,
          EVERY SINGLE ITEM WILL COME WITH A NUMBERED VERIFIABLE SCANNABLE QR
          CODE/NFC TAG THAT WILL BE VERIFIABLE ON-CHAIN. EVERY SINGLE ITEM WILL
          BE CONSIDERED CAREFULLY AND WILL BE CREATED IN SCARCE SUPPLY IN ORDER
          TO MAXIMIZE SECONDARY MARKET SALES POTENTIAL. THIS IS THE FUTURE, THIS
          IS THE FUTURE OF FASHION, THIS IS #BONEHEADS.
        </div>
      </div>
      <div styleName='content-wrapper mb-0'>
        <div styleName='content-headline'>
          WHAT DOES IT MEAN TO BE A BONEHEAD?
        </div>
        <div styleName='content-text content-text-sm text-uppercase'>
          It means that you believe in the future, and that it is already here.
          It means that you're striving, and exploring. It means that you see
          life through a different lens, a lens of opportunity. Boneheads are
          the misfits, the crazy ones. Boneheads are misunderstood genius that's
          shifting culture, one pixel at a time. Boneheads have been counted
          out, but never down. Life is beautiful, but we do not understand why
          we are here. However, collectively as a species, we are working
          towards uncovering this mystery. Maybe spaceheads will come down and
          share some of their super-intelligence with us one day, but until that
          day comes, we must continue to strive and move forward, onwards and
          upwards. Boneheads are your friendly neighbourhood reminder that every
          single one of us is capable of achieving what is perceived to be
          impossible - we've proven them wrong time and time again, and yet they
          still continue to doubt - but we understand that absolutely nothing is
          off the table. Conscious civilizations is entering a new stage, the
          digital revolution is upon us. What will life be like 10 years from
          now? 100 years? 1000 years? COLLECTIVELY, WE HAVE AN OPPORTUNITY TO
          write that story, in realtime. COME WRITE IT WITH US.
          #boneheadsforever. P.S. WE’RE HIRING. If YOU HAPPEN TO KNOW ANY
          CREATIVE DEVELOPERS (THAT WANT TO BUILD THE NEXT GENERATION OF COOL)
          FOR THE FOLLOWING ROLES THAT DO NOT CURRENTLY HAVE A FULL-TIME HOME,
          PLEASE REFER THEM TO US VIA TWITTER OR INSTAGRAM, IF ADOPTED, WE WILL
          REWARD YOU WITH SOMETHING COOL + A SMALL MONETARY REFERRAL REWARD.
          POSITIOn 1 - 3D MOTION GRAPHICS LEAD (MAYA). POSTITON 2: SENIOR
          ANGULAR ENGINEER. position 3: senior product designer lead. position
          4: sales development lead.
        </div>
      </div>
      <div styleName='forever'>#BONEHEADSFOREVER</div>
      <div styleName='getabone'>
        <MenuItemHolder>
          <Icon icon='mint' />
          <div styleName='item-text bold-text'>
            <Link to='/connect'>MINT A BONEHEAD</Link>
          </div>
        </MenuItemHolder>
      </div>
    </div>
  );
}
export default CSSModules(About, styles, options);
