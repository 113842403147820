/* eslint-disable react/style-prop-object */
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CSSModules from 'react-css-modules';
import localStyles from './contact.module.css';
import secondaryPageStyles from '../../shared/css/secondary-page.module.css';
import sharedStyles from '../../shared/css/shared.module.css';
import coverStyles from '../../shared/css/cover.module.css';
import MenuItemHolder from "../../components/MenuItemHolder/MenuItemHolder";
import Icon from "../../components/Icon/Icon";

const options = { allowMultiple: true };
const styles = {...localStyles, ...secondaryPageStyles, ...sharedStyles, ...coverStyles};

function Contact() {

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);

  return (
    <>
      <Helmet>
        <body style="overflow:hidden"></body>
      </Helmet>
    <div styleName="home-page page-fade-effect long-bg no-scroll fixed">
      <div styleName="page-title-container">
        <MenuItemHolder isMain={true} dClass="br-24p">
          <Icon isMainPage={true} icon="email" />
          <div styleName="item-text mail-text text-uppercase"><a href="mailto:contact@boneheads.io">contact@boneheads.io</a></div>
        </MenuItemHolder>
      </div>
    </div>
    </>
  );
}
export default CSSModules(Contact, styles, options);
