/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CSSModules from 'react-css-modules';
import secondaryPageStyles from '../../shared/css/secondary-page.module.css';
import sharedStyles from '../../shared/css/shared.module.css';
import coverStyles from '../../shared/css/cover.module.css';
import localStyles from './connect.module.css';
import Infura from './infura';
import { useState } from 'react';
import Web3 from 'web3';

// if contract is changed, don't forget to update the ABI
import CONTRACT_ABI from '../../assets/contract-abi.json';

const iconPath = `${process.env.PUBLIC_URL}/images/icons/mint.svg`;
const plusIconPath = `${process.env.PUBLIC_URL}/images/icons/plus.png`;
const minusIconPath = `${process.env.PUBLIC_URL}/images/icons/minus.png`;
const openseaIconPath = `${process.env.PUBLIC_URL}/images/icons/opensea.png`;

const options = { allowMultiple: true };
const styles = {
  ...secondaryPageStyles,
  ...sharedStyles,
  ...coverStyles,
  ...localStyles,
};

function Connect() {
  const { ethereum } = window;
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = React.useState(false);
  const [isMetaMaskConnected, setIsMetaMaskConnected] = React.useState(false);
  const [totalSupply, setTotalSupply] = React.useState();
  const [calcTotalSupply, setCalcTotalSupply] = React.useState();
  const [hash, setHash] = React.useState('');

  // replace this with your contract's address
  const CONTRACT_ADDRESS = '0xf9C2874A88ED6C5a8115CB5693996C8fE22B3CE2';

  // eslint-disable-next-line no-unused-vars
  const [walletReady, setWalletReady] = useState(false);
  const [selectedCount, setSelectedCount] = useState(1);
  const [success, setSuccess] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [saleState, setSaleState] = useState(false);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();

    console.log(window.ethereum);
    setIsMetaMaskInstalled(Boolean(ethereum && ethereum.isMetaMask));
    setIsMetaMaskConnected(Boolean(walletReady));

    getSaleState();
  }, [walletReady]);

  // eslint-disable-next-line no-unused-vars
  async function connectWeb3() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      if (accounts.length === 0) {
        setWalletReady(false);
      } else {
        setWalletReady(true);
      }
    }
  }

  async function mintBoneheads() {
    const curr_address = (
      await window.ethereum.request({ method: 'eth_requestAccounts' })
    )[0];
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
    const payableAmount =
      web3.utils.toWei(web3.utils.toBN(selectedCount), 'ether') * 0.1;

    contract.methods
      .mint(selectedCount)
      .send({ from: curr_address, value: payableAmount })
      .on('error', (error) => {
        console.log(error);
        alert(`Error ${error.code}: ${error.message}`);
      })
      .on('transactionHash', function (hash) {
        alert(
          `Please wait for the transaction to confirm. Transaction hash: ${hash}`
        );
        setHash(hash);
      })
      .on('receipt', (receipt) => setSuccess(true));
  }

  const onCounterChange = (type) => {
    if (type === 0) {
      if (selectedCount > 1) {
        let count = selectedCount - 1;
        setSelectedCount(count);
      }
    } else {
      if (selectedCount < 50) {
        let count = selectedCount + 1;
        setSelectedCount(count);
      }
    }
  };

  async function getSaleState() {
    const INFURA =
      'https://mainnet.infura.io/v3/4b48220ef22f43c1a1c842c850869019';
    const PROVIDER = new Web3.providers.HttpProvider(INFURA);
    const WEB3 = new Web3(PROVIDER);

    const boneheadsContract = new WEB3.eth.Contract(CONTRACT_ABI);
    boneheadsContract.options.address = CONTRACT_ADDRESS;
    boneheadsContract.methods.saleIsActive
      .call()
      .call()
      .then((bool) => {
        setSaleState(bool);
      })
      .catch((e) => console.error(e));
  }

  async function getSoldOutState() {
    const INFURA =
      'https://mainnet.infura.io/v3/4b48220ef22f43c1a1c842c850869019';
    const PROVIDER = new Web3.providers.HttpProvider(INFURA);
    const WEB3 = new Web3(PROVIDER);

    const boneheadsContract = new WEB3.eth.Contract(CONTRACT_ABI);
    boneheadsContract.options.address = CONTRACT_ADDRESS;
    boneheadsContract.methods
      .totalSupply()
      .call()
      .then((val) => {
        setIsSoldOut(val === 10000);
      })
      .catch((e) => console.error(e));
  }

  return (
    <>
      <Helmet>
        <body style="overflow:hidden"></body>
      </Helmet>
      <div styleName="home-page page-fade-effect long-bg fixed">
        <div className="connect-meta">
          {isSoldOut ? (
            <div styleName="page-title-container">
              <div styleName="total-minted">
                <div styleName="hashtag-sold">#BONEHEADSFOREVER</div>
                <div styleName="sold-out">SOLD OUT</div>
                <div styleName="get-metamask">
                  <a
                    styleName="yellow-btn d-i-flex"
                    href="http://www.metamask.io"
                    target="_blank"
                  >
                    <img styleName="icon" src={openseaIconPath} alt="opensea" />
                    <div styleName="btn-text cursor-poniter">
                      BUY ON OPENSEA
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ) : success ? (
            <div styleName="page-title-container">
              <div styleName="yellow-btn">
                <img styleName="icon" src={iconPath} alt="mint" />
                <div styleName="btn-text">TRANSACTION SUCCESSFUL</div>
              </div>
              <div className="etherscanlink">
                Etherscan Link : https://etherscan.io/tx/{hash}
              </div>
            </div>
          ) : walletReady === false ? (
            <div styleName="page-title-container">
              <div styleName="total-minted">
                <Infura
                  calcTotalSupply={calcTotalSupply}
                  totalSupply={totalSupply}
                  setTotalSupply={setTotalSupply}
                />
                {isMetaMaskInstalled ? (
                  <div>
                    <div styleName="get-metamask" onClick={connectWeb3}>
                      <div styleName="yellow-btn d-i-flex">
                        <img styleName="icon" src={iconPath} alt="mint" />
                        <div styleName="btn-text cursor-poniter">
                          Connect Metamask
                        </div>
                      </div>
                    </div>
                    <div styleName="alert">
                      {/* <div styleName="alert-text">
                        YOU NEED TO CONNECT TO ETHEREUM MAINNET
                      </div>
                    </div>
                    <div styleName="alert-mobile">
                      <div styleName="alert-text">
                        YOU NEED TO CONNECT TO MAINNET
                      </div> */}
                    </div>
                  </div>
                ) : (
                  <div styleName="get-metamask">
                    <a
                      styleName="yellow-btn d-i-flex"
                      href="http://www.metamask.io"
                      target="_blank"
                    >
                      <img styleName="icon" src={iconPath} alt="mint" />
                      <div styleName="btn-text cursor-poniter">
                        Get Metamask
                      </div>
                    </a>
                    <div styleName="alert">
                      <div styleName="alert-text">
                        PLEASE USE A BROWSER THAT SUPPORTS METAMASK
                      </div>
                    </div>
                    <div styleName="alert-mobile">
                      <div styleName="alert-text">
                        PLEASE USE A BROWSER THAT SUPPORTS METAMASK
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div styleName="page-title-container" className="mint-row">
              <div styleName="total-minted">
                <div styleName="heading">QUANTITY</div>
                <div styleName="qty-container">
                  <img
                    styleName="counter-img"
                    src={minusIconPath}
                    alt="minus"
                    onClick={() => onCounterChange(0)}
                  />
                  <h1 styleName="counter-value">{selectedCount}</h1>
                  <img
                    styleName="counter-img"
                    src={plusIconPath}
                    alt="plus"
                    onClick={() => onCounterChange(1)}
                  />
                </div>
                <button
                  disabled={!saleState || isSoldOut}
                  onClick={mintBoneheads}
                  styleName="yellow-btn"
                >
                  <img styleName="icon" src={iconPath} alt="mint" />
                  <div styleName="btn-text cursor-poniter">
                    MINT = {(selectedCount * 0.1).toFixed(2)} ETH
                  </div>
                </button>
                {hash && (
                  <a href={hash} className="etherscanlink">
                    {hash}
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default CSSModules(Connect, styles, options);
