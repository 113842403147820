import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import localStyles from './benefits.module.css';
import secondaryPageStyles from '../../shared/css/secondary-page.module.css';
import sharedStyles from '../../shared/css/shared.module.css';
import MenuItemHolder from '../../components/MenuItemHolder/MenuItemHolder';
import Icon from '../../components/Icon/Icon';

const options = { allowMultiple: true };
const styles = {...secondaryPageStyles, ...localStyles, ...sharedStyles};

function Benefits() {
  return (
    <div
      styleName='home-page page-fade-effect long-bg'
      style={{ backgroundImage: "url('/images/BG2.png')" }}
    >
      <div styleName='page-title-container'>
        <div styleName='page-title-block'>Benefits</div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>1 - LIFETIME MEMBERSHIP</div>
        <div styleName='content-text content-text-sm'>
          OWNING A #BONEHEAD GRANTS YOU A LIFETIME MEMBERSHIP (AS LONG AS YOU
          HOLD IT IN YOUR WALLET) TO THE CABANA, THE FORGING HQ, WHERE YOU WILL
          BE ABLE TO FORGE PHYSICAL AND DIGITAL COLLECTIBLES.
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>2 - EXCLUSIVE FORGING</div>
        <div styleName='content-text content-text-sm text-uppercase'>
          ONLY USERS HOLDING A #BONEHEAD IN THEIR WALLET WILL BE ABLE TO
          MINT/FORGE AN "X" (TBD) AMOUNT OF ITEMS FROM EACH COLLECTION DROP -
          THIS NUMBER WILL FLUCTUATE DEPENDING ON THE QUANTITY THAT IS PRODUCED.
          THE MORE #BONEHEAD THAT YOU OWN, THE MORE ITEMS YOU WILL BE ABLE TO
          FORGE. THERE WILL BE CONTINGENCIES ATTACHED TO THE ITEMS THAT YOU ARE
          PERMITTED TO FORGE. IF YOUR SPECIFIC #BONEHEAD LACKS AN ATTRIBUTE
          (SUCH AS A ZEBRA-PATTERNED BUCKET HAT), YOU WILL NOT BE ABLE TO FORGE
          THE PHYSICAL ITEM ASSOCIATED WITH THIS ATTRIBUTE. YOU WILL HAVE TO
          ACQUIRE A #BONEHEAD THAT HAS THIS PARTICULAR ATTRIBUTE/TRAIT/ITEM
          THROUGH THE SECONDARY MARKET IN ORDER TO FORGE THIS PHYSICAL
          COLLECTIBLE - THIS WILL DRIVE SPECULATION AND PRODUCE A NEW ELEMENT TO
          YOUR #BONEHEADS RARITY, THUS CREATE A MORE DYNAMIC ASSET CLASS.
          PHYSICAL COLLECTIBLES (COMMODITIES) WILL HAVE A QR CODE THAT WILL
          VERIFY THEIR AUTHENTICITY ON-CHAIN, FOREVER. **WHERE'S THE FUN IN
          STUNTING AND FLEXING IN THE METAVERSE IF YOU CAN'T STUNT THE SAME WAY
          IN REAL LIFE?** PHYSICAL + DIGITAL = #NOMOREFAKES, #BONEHEADSFOREVER
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>3 - CLAIMABLES</div>
        <div styleName='content-text content-text-sm'>
          TOKEN HOLDERS WILL HAVE ACCESS TO SEVERAL CLAIMABLE THAT WE CURRENTLY
          HAVE ON THE ROADMAP (SPOILER ALERT: BABY BONES). STAY TUNED. *TX =
          TRANSACTION GAS PAID BY MINTER (YOU)
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>4 - APP ACCESS</div>
        <div styleName='content-text content-text-sm'>
          TOKEN HOLDERS WILL BE THE FIRST TO HAVE AN OPPORTUNITY TO ACCESS AND
          TEST OUR CONSUMER-FACING AVATAR CREATION APP DURING THE BETA RELEASE.
          THIS APP WILL ALLOW YOU TO CREATE A PERSONAL AVATAR FOR YOURSELF USING
          AN EXPANSIVE CATALOG OF CLOTHING AND ACCESSORIES TO SUIT YOUR UNIQUE
          STYLE. #BONEHEADSFOREVER
        </div>
      </div>
      <div styleName='content-wrapper'>
        <div styleName='content-headline'>5 - BEHIND THE SCENES</div>
        <div styleName='content-text content-text-sm'>
          TOKEN HOLDERS WILL GET EXCLUSIVE BEHIND-THE-SCENES ACCESS THROUGHOUT
          THE ENTIRE MANUFACTURING PROCESS FOR ALL OF OUR ITEMS -- THIS INCLUDES
          BUT IS NOT LIMITED TO: SCULPTURES, AREA RUGS, AND ACCESSORIES SUCH AS
          PLAYING CARDS, ASHTRAYS, BOXING GLOVES, GARMENTS, AND OTHER
          COLLECTIBLES. #BONEHEADSFOREVER
        </div>
      </div>
      <div styleName='content-wrapper mb-0'>
        <div styleName='content-headline'>6 - VOTING RIGHTS</div>
        <div styleName='content-text content-text-sm'>
          TOKEN HOLDERS AND THE #BONEGANG COMMUNITY WILL VOTE AND PROVIDE
          FEEDBACK THROUGHOUT THE SAMPLING PROCESS WHILE WE TEST THE FABRICS,
          TEXTILES, AND PATTERNS THAT WILL EVENTUALLY BE PLACED INTO PRODUCTION.
          WE WILL PROVIDE YOU WITH A THOROUGH BREAKDOWN OF THE TIMELINES, AND
          THE ENTIRE MANUFACTURING PROCESS FROM START TO FINISH - EVERYTHING
          WILL BE DOCUMENTED AND SHARED WITH OUR #BONEGANG COMMUNITY.
          #BONEHEADSFOREVER
        </div>
      </div>
      <div styleName='forever'>#BONEHEADSFOREVER</div>
      <div styleName='getabone'>
        <MenuItemHolder>
          <Icon icon='mint' />
          <div styleName='item-text bold-text'>
          <Link to={`${process.env.PUBLIC_URL}/connect`}>
              MINT A BONEHEAD
            </Link>
            </div>
        </MenuItemHolder>
      </div>
    </div>
  );
}
export default CSSModules(Benefits, styles, options);
