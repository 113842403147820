import { useLocation } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './footer.module.css';
import IconHolder from '../IconHolder/IconHolder';
import Icon from '../Icon/Icon';
const lockIcon = `${process.env.PUBLIC_URL}/images/icons/lock.png`;

const options = { allowMultiple: true };

function Footer() {
  const location = useLocation();

  const isMintPage = () => location.pathname === '/connect';

  return (
    <div styleName="footer">
      <div styleName={`left-nav ${isMintPage() ? 'hide-left-nav' : ''}`}>
        <IconHolder>
          <Icon icon="twitter" link="https://twitter.com/boneheadssss" />
        </IconHolder>
        <IconHolder>
          <Icon icon="discord" link="https://discord.com/invite/boneheads" />
        </IconHolder>
        <IconHolder>
          <Icon icon="instagram" link="https://www.instagram.com/boneheads/" />
        </IconHolder>
        <IconHolder>
          <Icon
            icon="opensea"
            link="https://opensea.io/collection/boneheadsofficial"
          />
        </IconHolder>
        {/* TODO: It will be uncommented after the link is passed */}
        {/* <IconHolder>
          <Icon icon="OS" />
        </IconHolder> */}
      </div>
      {isMintPage() ? (
        <a
          href="https://etherscan.io/address/0xf9c2874a88ed6c5a8115cb5693996c8fe22b3ce2#code"
          rel="noreferrer"
          target="_blank"
          styleName="contract-btn"
        >
          <img src={lockIcon} alt="lock" />
          <span>CONTRACT</span>
        </a>
      ) : null}
      <div styleName="right-nav">&copy; 2021 BONEHEADS</div>
    </div>
  );
}
// . <span styleName="tos">TOS</span>
export default CSSModules(Footer, styles, options);
