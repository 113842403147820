import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CSSModules from 'react-css-modules';
import styles from './header.module.css';
import MenuItemHolder from "../MenuItemHolder/MenuItemHolder";
import IconHolder from "../IconHolder/IconHolder";
import Icon from "../Icon/Icon";
import DropDown from "../Dropdown/dropdown";
import navItems from "../../data/menu-items";
import DropdownComponent from "../DropdownComponent/dropdown-component";

const options = { allowMultiple: true };

function Header() {
  const location = useLocation();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );
    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const isDropPage = () => {
    return (
      location.pathname === '/puffer' || 
      location.pathname === '/biker' ||
      location.pathname === '/bomber'
    )
  }

  const isStorePage = () => {
    return location.pathname === '/store';
  }

  const storePageLink = (
    <Link to={`${process.env.PUBLIC_URL}/connect`}>
      <MenuItemHolder>
        <Icon icon="mint" />
        <div styleName="item-text bold-text">
          FORGE COLLECTIBLES
        </div>
      </MenuItemHolder>
    </Link> 
   )

   const dropPageLink = (
    <Link to={`${process.env.PUBLIC_URL}/connect`}>
      <MenuItemHolder>
        <Icon icon="mint" />
        <div styleName="item-text bold-text">
          FORGING SOON
        </div>
      </MenuItemHolder>
    </Link>
   )

   const connectPageLink = (
    <Link to={`${process.env.PUBLIC_URL}/connect`}>
      <MenuItemHolder>
        <Icon icon="mint" />
        <div styleName="item-text bold-text">
          MINT A BONEHEAD
        </div>
      </MenuItemHolder>
    </Link>
   )
   //  This button will be used later conditionally
   // eslint-disable-next-line no-unused-vars
   const openseaLink = (
    <Link to="">
      <MenuItemHolder>
        <Icon icon="opensea" />
        <div styleName="item-text bold-text">
          BUY ON OPENSEA
        </div>
      </MenuItemHolder>
    </Link>
   )

  return (
    <div
      styleName="header"
      style={{ top: visible ? "0" : "-65px" }}
    >
      <div styleName="left-nav mobile">
        <DropDown list={navItems.primaryMobile} />
      </div>
      <div styleName="left-nav desktop">
        <Link to={`${process.env.PUBLIC_URL}/`}>
          <IconHolder>
            <Icon icon="boneheads" />
          </IconHolder>
        </Link>
        <DropdownComponent icon="question" list={navItems.primary} />
        <DropdownComponent icon="location" list={navItems.secondary} />
        <DropdownComponent icon="box" list={navItems.dropList} />
        <Link to={`${process.env.PUBLIC_URL}/connect`}>
          <IconHolder>
            <Icon icon="mint" />
          </IconHolder>
        </Link>
      </div>
      <div styleName="center-nav">
        <div styleName="mobile">
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <IconHolder>
              <Icon icon="boneheads" />
            </IconHolder>
          </Link>
        </div>
      </div>
      <div styleName="right-nav mobile-nav">
        <div styleName="mobile">
          <Link to={`${process.env.PUBLIC_URL}/connect`}>
            <IconHolder>
              <Icon icon="mint" />
            </IconHolder>
          </Link>
          {/* <Link to="">
            <IconHolder>
              <Icon icon="opensea" />
            </IconHolder>
          </Link> */}
        </div>
        <div styleName="desktop">
          {
            isDropPage() ?
             dropPageLink
            : isStorePage()
              ?
              storePageLink
                : connectPageLink
          }
        </div>
        {/* <div styleName="mobile">
          <IconHolder>
            <Icon icon="email" />
          </IconHolder>
        </div>
        <div styleName="desktop">
          <MenuItemHolder>
            <Icon icon="email" />
            <div styleName="item-text bold-text">GET NOTIFIED</div>
          </MenuItemHolder>
        </div>
        <div styleName="desktop">
          <IconHolder>
            <Icon icon="volume" />
          </IconHolder>
        </div> */}
      </div>
    </div>
  );
}
export default CSSModules(Header, styles, options);

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
