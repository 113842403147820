import React from 'react';
import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import CSSModules from 'react-css-modules';
import localStyles from './community.module.css';
import sharedStyles from '../../shared/css/shared.module.css';
import { config } from '../../firebase-config';
import MenuItemHolder from '../../components/MenuItemHolder/MenuItemHolder';
import Icon from '../../components/Icon/Icon';

const options = { allowMultiple: true };
const styles = { ...localStyles, ...sharedStyles };

const app = firebase.apps.length
  ? firebase.app()
  : firebase.initializeApp(config);
const db = firebase.firestore(app);

function Community() {
  
  const [state, setState] = useState({
    users: [],
    participated: 0,
    total: 0,
  });

  useEffect(() => {
    db.collection('users')
      .get()
      .then((snapshot) =>
        snapshot.docs.map((doc) => ({ uuid: doc.id, ...doc.data() }))
      )
      .then((users) => {
        const total = users.length;
        const participated = users.filter((user) => user.participated).length;
        setState({ total, participated, users });
      });
  }, []);

  return (
    <div
      styleName='home-page page-fade-effect long-bg'
      style={{ backgroundImage: "url('/images/BG2.png')" }}
    >
      <div styleName='page-title-container'>
        <div styleName='page-title-block'>COMMUNITY ACTIVATION</div>
      </div>
      <div styleName='content-headline'>
        I RECEIVED A COMMUNITY ACTIVATION REQUEST, WHAT DOES THIS MEAN?
      </div>
      <div styleName='content-text mb-10r content-text-sm'>
        <p styleName='text-uppercase'>
          THIS MEANS THAT WE VALUE YOUR OPINION AND CONSIDER YOU TO BE AN
          AUTHORITY FIGURE IN THEe SPACE, and are requesting you TO PROVIDE AN
          HONEST OPINION ON #boneheads. IN EXCHANGE FOR YOUR TIME AND PLATFORM,
          YOU WILL BE:{' '}
        </p>
        <p styleName='text-uppercase text-green'>
          1 - TRANSFERED A #BONEHEAD TOKEN AFTER THE LAUNCH
        </p>
        <p styleName='text-uppercase'>
          <span styleName='text-green'>
            2 - ADDED TO The cOMMUNITY ACTIVATION WALLET*{' '}
          </span>
          (5% of the TOKEN sale funds will BE dispersed evenly amongst the
          community members that participate).
        </p>
        <p styleName='text-uppercase'>
          <span styleName='text-green'>
            3 - added to the secondary sales community wallet*{' '}
          </span>
          (5% of secondary sales FROM THE 1ST YEAR will dispersed evenly amongst
          the community members that participate)
        </p>
        <p styleName='text-uppercase'>
          WE'VE LISTED THE NOTABLE COMMUNITY MEMBERS' TWITTER ACCOUNTS BELOW
          WHERE YOU MIGHT DISCOVER HELPFUL INSIGHTS ABOUT THE NFT COMMUNITY. IF
          YOU’RE NOT ON THE LIST, KINDLY DM{' '}
          <a
            href='https://twitter.com/ivybonee'
            rel='noreferrer'
            target='_blank'
          >
            @IVYBONEE
          </a>{' '}
          OR{' '}
          <a
            href='https://twitter.com/boneheadssss'
            rel='noreferrer'
            target='_blank'
          >
            @BONEHEADSSSS
          </a>{' '}
          ON TWITTER AND WE WILL REVIEW YOUR PROFILE.
        </p>
      </div>
      <div styleName='content-headline'>CLAIM YOUR ACTIVATION</div>
      <div styleName='content-text mb-10r content-text-sm'>
        <p styleName='text-uppercase'>
          IN ORDER TO QUALITY FOR THe ACTIVATION, YOU MUST:
        </p>
        <p styleName='text-uppercase text-green'>
          1 - FOLLOW{' '}
          <a
            styleName='text-green'
            href='https://twitter.com/boneheadssss'
            rel='noreferrer'
            target='_blank'
          >
            @BONEHEADSSSS
          </a>
          ,{' '}
          <a
            styleName='text-green'
            href='https://twitter.com/ivybonee'
            rel='noreferrer'
            target='_blank'
          >
            @IVYBONEE
          </a>
          , and{' '}
          <a
            styleName='text-green'
            href='https://twitter.com/nikkibonee'
            rel='noreferrer'
            target='_blank'
          >
            @NIKKIBONEE
          </a>{' '}
          On TWITTER
        </p>
        <p styleName='text-uppercase text-green'>
          2 - RETWEET/quote the PINNED GIVEAWAY TWEET from{' '}
          <a
            styleName='text-green'
            href='https://twitter.com/boneheadssss'
            rel='noreferrer'
            target='_blank'
          >
            @BONEHEADSSSS
          </a>{' '}
          while providing a short opinion
        </p>
        <p styleName='text-uppercase'>
          <span styleName='text-red'>
            IT IS IMPORTANT TO NOTE THAT THE INDIVIDUALS LISTED BELOW ARE NOT
            ASSOCIATED WITH BONEHEADS INC. OR THIS PROJECT IN ANY CAPACITY
            (UNLESS NOTED OTHERWISE), AND THE OPINIONS THAT ARE EXPRESSED BY
            THEM OR ON THEIR TWITTER ACCOUNTS ARE NOT THOSE EXPRESSED BY
            BONEHEADS INC., OUR PARTNERS, INVESTORS, OR ANY ENTITY THAT IS
            ASSOCIATED WITH #BONEHEADS.{' '}
          </span>
          THIS IS NOT FINANCIAL ADVISE. IF AT ANYTIME YOU WOULD LIKE TO BE
          REMOVED FROM THIS LIST PLEASE SEND AN EMAIL TO REMOVAL@BONEHEADS.IO
          AND YOU WILL BE REMOVED.
        </p>
        <p styleName='text-uppercase'>
          *at least 75 of the listed members below need to participate in order
          to activate the community wallets.
        </p>
      </div>
      <div styleName='content-headline'>NOTABLE COMMUNITY FIGURES</div>
      <div styleName='content-text content-text-sm mb-0'>
        <p styleName='text-purple text-uppercase'>
          PARTICIPATING: {state.participated} / {state.total}
        </p>
      </div>
      <div styleName='content-text content-text-sm mb-0 mt-6-25r'>
        {state.users.map((user) => (
          <a
            key={user.uuid}
            styleName={`click-handler text-uppercase ${
              user.participated ? 'text-green' : ''
            }`}
            href={user.url}
            rel='noreferrer'
            target='_blank'
          >
            {user.handle}
          </a>
        ))}
      </div>
      <div styleName='forever'>#BONEHEADSFOREVER</div>
      <div styleName='getabone'>
        <MenuItemHolder>
          <Icon icon='mint' />
          <div styleName='item-text bold-text'>MINT A BONEHEAD</div>
        </MenuItemHolder>
      </div>
    </div>
  );
}

export default CSSModules(Community, styles, options);
