import CSSModules from 'react-css-modules';
import styles from './icon-holder.module.css';

const options = { allowMultiple: true };

function IconHolder(props){
  return(
    <div styleName="item-holder">
      {props.children}
    </div>
  )
}
export default CSSModules(IconHolder, styles, options);