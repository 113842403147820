import { Link } from "react-router-dom";
import CSSModules from 'react-css-modules';
import styles from './dropdown-list.module.css';
import MenuItemHolder from "../MenuItemHolder/MenuItemHolder";
import Icon from "../Icon/Icon";

const options = { allowMultiple: true };

function DropdownList(props) {
  const menuItems = props.list;
  const onChange = props.onChange ? props.onChange : () => {};
  return menuItems.map((item, index) => {
    return (
      <li key={index}>
        <Link to={`${process.env.PUBLIC_URL}${item.url}`} onClick={() => onChange(index)}>
          <MenuItemHolder dropdownItem={true} isLocked={!item.status? true: false}>
            <div>
              <Icon icon={item.icon} />
            </div>
            <div styleName="item-text bold-text flex align-center">
              <span>{item.name}</span>
              {!item.status && (
                <div styleName="locked-icon">
                  <Icon icon="lock" />
                </div>
              )}
            </div>
          </MenuItemHolder>
        </Link>
      </li>
    );
  });
}
export default CSSModules(DropdownList, styles, options);
