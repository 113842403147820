import CSSModules from 'react-css-modules';
import styles from './icon.module.css';

const options = { allowMultiple: true };

function Icon(props){
  const icon = props.icon;
  const link = props.link ? props.link : null;
  const iconPath = `${process.env.PUBLIC_URL}/images/icons/${icon}.svg`;
  const isMainPage = props.isMainPage ? "main-page-icon": "";
  return(
    <>
    {link ? 
    <a href={link} rel="noreferrer" target="_blank">
      <img src={iconPath} alt={icon} />
    </a>
    : <img styleName={isMainPage} src={iconPath} alt={icon} />}
    </>
  );
}
export default CSSModules(Icon, styles, options);